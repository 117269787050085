import React, { useContext } from "react";
import { ProfileContext } from "../context/ProfileContext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

export default function Header() {
  const context = useContext(ProfileContext);

  console.log(context);

  return (
    <Box sx={{ background: "linear-gradient(0deg, #212121, #3E1391)" }}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: { xs: 2, md: 5 },
        }}
      >
        <img
          src="/logo.svg"
          alt="FlipSystem Logo"
          style={{ maxWidth: "300px", marginTop: 20, marginBottom: 20 }}
        />
        <Box sx={{ margin: 1 }}>
          <Typography
            variant="h1"
            textAlign="center"
            sx={{ fontSize: { xs: 45, md: 60 } }}
          >
            {context.hubspotData.firstname} {context.hubspotData.lastname},
          </Typography>
          <Typography
            variant="h1"
            textAlign="center"
            sx={{ fontSize: { xs: 45, md: 60 } }}
          >
            Welcome to FlipSystem!
          </Typography>
        </Box>
        <Box sx={{ margin: 1 }}>
          <Typography textAlign="center">
            Please watch this video before your next{" "}
            <span
              onClick={() =>
                context.setAdminViewVisible(!context.isAdminViewVisible)
              }
            >
              meeting!
            </span>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
