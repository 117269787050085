import React from "react";
import { Tooltip } from "@mui/material";

export default function AnimatedCheckmark({ checked, onClick, style }) {
  const totalPathLengthCheck = 30; // This is an approximation of the path's length for the checkmark.
  const totalPathLengthX = 45; // This is an approximation of the path's length for the X mark.

  return (
    <Tooltip title={checked ? "Mark Incomplete" : "Mark Complete"}>
      <svg
        onClick={onClick}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        style={{ cursor: "pointer", transform: "scale(0.9)", ...style }}
      >
        <rect
          x="1"
          y="1"
          width="38"
          height="38"
          rx="5" // Rounded corners
          fill={checked ? "#e1fac8" : "#282729"} // Change background based on checked status
          strokeWidth="2"
        />
        <path
          d="M12 22l6 6 10-16"
          stroke={checked ? "#408941" : "none"} // Checkmark visible only when checked
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray={totalPathLengthCheck}
          strokeDashoffset={checked ? 0 : totalPathLengthCheck}
          style={{
            transition:
              "stroke-dashoffset 0.3s ease-in-out, stroke 0.3s ease-in-out",
          }}
        />
        <path
          d="M12 12l16 16"
          stroke={!checked ? "#fff" : "none"} // White X mark visible only when not checked
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray={totalPathLengthX}
          strokeDashoffset={!checked ? 0 : totalPathLengthX}
          style={{
            transition:
              "stroke-dashoffset 0.3s ease-in-out, stroke 0.3s ease-in-out",
          }}
        />
        <path
          d="M28 12l-16 16"
          stroke={!checked ? "#fff" : "none"} // White X mark visible only when not checked
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray={totalPathLengthX}
          strokeDashoffset={!checked ? 0 : totalPathLengthX}
          style={{
            transition:
              "stroke-dashoffset 0.3s ease-in-out, stroke 0.3s ease-in-out",
          }}
        />
        <path
          d="M12 12l16 16"
          stroke={!checked ? "#282729" : "none"} // Darker X mark visible only when not checked
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray={totalPathLengthX}
          strokeDashoffset={!checked ? 0 : totalPathLengthX}
          style={{
            transition:
              "stroke-dashoffset 0.3s ease-in-out, stroke 0.3s ease-in-out",
          }}
        />
        <path
          d="M28 12l-16 16"
          stroke={!checked ? "#282729" : "none"} // Darker X mark visible only when not checked
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray={totalPathLengthX}
          strokeDashoffset={!checked ? 0 : totalPathLengthX}
          style={{
            transition:
              "stroke-dashoffset 0.3s ease-in-out, stroke 0.3s ease-in-out",
          }}
        />
      </svg>
    </Tooltip>
  );
}
