import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { firebaseApp } from "./firebase";

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

export async function fetchProfileData(hs_id) {
  const docRef = doc(db, "profiles", hs_id);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? docSnap.data() : null;
}

// Save profile data, create if not exists
export async function saveProfileData(hs_id, data) {
  const docRef = doc(db, "profiles", hs_id);
  return setDoc(docRef, data, { merge: true });
}

export async function addLinkToClickedLinks(hs_id, link) {
  console.log("ADDING CLICKED LINK ", hs_id, link);
  try {
    const profileData = await fetchProfileData(hs_id);
    if (!profileData) {
      console.error("Profile data is undefined or null");
      return;
    }

    const currentClickedLinks = profileData.clickedLinks || [];
    console.log(currentClickedLinks);

    // Check if the link is already clicked
    if (!currentClickedLinks.includes(link)) {
      currentClickedLinks.push(link);
      console.log(currentClickedLinks);
      await saveProfileData(hs_id, { clickedLinks: currentClickedLinks });
    }
  } catch (error) {
    console.error("Error adding link to clicked links:", error);
  }
}

export async function fetchAllData(hs_id) {
  console.log("FETCHING HUBSPOT DATA");
  const hubspotData = await fetchHubspotData(hs_id);
  if (!hubspotData) {
    throw new Error("Failed to fetch HubSpot data.");
  }

  console.log("HUBSPOT DATA ", hubspotData);

  console.log("FETCHING PROFILE DATA");

  const profilesData = await fetchProfilesData(hs_id);

  console.log("PROFILES DATA ", profilesData);

  const advisorsData = await fetchAdvisorsData(
    hubspotData.ownerEmail,
    hubspotData.setterEmail
  );

  console.log("ADVISORS DATA ", advisorsData);

  return {
    hubspot: hubspotData,
    profiles: profilesData,
    advisors: advisorsData,
  };
}

async function fetchHubspotData(hs_id) {
  const hubspotDocRef = doc(db, "hubspot", hs_id);
  const hubspotDoc = await getDoc(hubspotDocRef);

  if (hubspotDoc.exists()) {
    const data = hubspotDoc.data();
    const lastUpdated = data.timestamp ? new Date(data.timestamp).getTime() : 0;
    const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;

    if (lastUpdated > fiveMinutesAgo) {
      return data;
    }
  }

  const newData = await fetchNewHubspotData(hs_id);
  if (newData) {
    await setDoc(hubspotDocRef, {
      ...newData,
      timestamp: new Date().toISOString(),
    });
    return newData;
  }

  throw new Error("Failed to fetch new HubSpot data");
}

async function fetchNewHubspotData(hs_id) {
  const apiBaseUrl =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:3000"; // Default to localhost if not specified
  try {
    const response = await fetch(
      `${apiBaseUrl}/api/getContact/?hs_id=${hs_id}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data from HubSpot API");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching new HubSpot data:", error);
    return null;
  }
}

async function fetchProfilesData(hs_id) {
  const docRef = doc(db, "profiles", hs_id); // Directly reference the document by ID
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data(); // Return the data if document exists
  } else {
    return null; // Return null if no document found
  }
}

async function fetchAdvisorsData(ownerEmail, setterEmail) {
  const emails = [ownerEmail, setterEmail].filter(
    (email) => email !== undefined
  );

  if (emails.length === 0) {
    throw new Error("At least one email must be provided");
  }

  const advisorsQuery = query(
    collection(db, "advisors"),
    where("email", "in", emails)
  );
  const advisorsSnapshot = await getDocs(advisorsQuery);
  return advisorsSnapshot.docs.map((doc) => doc.data());
}

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, "users", uid)).then(format);
}

export function getTestDocs() {
  // If fetching a specific document:
  // return getDoc(doc(db, "test", "specificDocumentId")).then(snapshot => snapshot.data());

  // If fetching all documents from the "test" collection:
  return getDocs(collection(db, "test")).then((querySnapshot) => {
    return querySnapshot.docs.map((doc) => doc.data());
  });
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
