import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { ProfileContext } from "../context/ProfileContext";

export default function AdvisorsComponent() {
  const { setter, closer } = useContext(ProfileContext);

  const placeholders = {
    headshot: "/default-avatar.png",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    description: "",
  };

  const descriptions = {
    rep1: "With years of experience in real estate and a passion for helping others achieve financial freedom, [Rep Name] is here to guide you through the first steps of our process. [Rep Name] will take the time to understand your unique goals and ensure you're set up for success with our program.",
    rep2: "As a seasoned real estate professional, [Rep Name] is dedicated to helping you take the next steps in your investment journey. [Rep Name] brings valuable insights and a wealth of knowledge to each conversation, ensuring that we can customize a plan that aligns with your long-term goals.",
  };

  const displayAdvisor = (advisor, descriptionTemplate) => {
    const description = advisor.description
      ? advisor.description
      : descriptionTemplate
          .replaceAll("[Rep Name]", `${advisor.firstname}`)
          .replaceAll("[He/She/They]", advisor.pronoun || "They");

    return (
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            background: "#363240",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={advisor.headshot || placeholders.headshot}
              sx={{
                width: { xs: 75, md: 100 },
                height: { xs: 75, md: 100 },
                mr: { xs: 1.5, md: 3 },
              }}
            />
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  wordBreak: "break-word",
                  color: "#fff",
                }}
              >
                {advisor.firstname || placeholders.firstname}{" "}
                {advisor.lastname || placeholders.lastname}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
              >
                {advisor.email || placeholders.email}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: { xs: "0.9rem", md: "0.95rem" } }}
              >
                {advisor.phone || placeholders.phone}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontStyle: "italic",
                  fontWeight: "light",
                  fontSize: "0.875rem",
                  mt: 2,
                }}
              >
                {description || placeholders.description}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const advisors = [];
  if (setter) advisors.push({ ...setter });
  if (closer && (!setter || setter.email !== closer.email))
    advisors.push({ ...closer });
  const advisorCount = advisors.length;

  return (
    <Box sx={{ m: 1, mt: 3 }}>
      {advisorCount > 0 && (
        <Typography
          variant="h6"
          textAlign={{ xs: "center", md: "left" }}
          sx={{ mb: 1 }}
        >
          {advisorCount > 1 ? "Meet Your Advisors" : "Meet Your Advisor"}
        </Typography>
      )}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        {advisors.map((advisor, index) =>
          displayAdvisor(
            advisor,
            index === 0 ? descriptions.rep1 : descriptions.rep2
          )
        )}
      </Grid>
    </Box>
  );
}
