import React, { useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Box } from "@mui/material";
import AnimatedCheckmark from "./AnimatedCheckmark";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import { ProfileContext } from "../context/ProfileContext";

export default function CustomAccordion({
  title,
  children,
  expanded,
  onChange,
  defaultChecked,
  icon,
}) {
  const { checkedAccordions, updateCheckedAccordions } =
    useContext(ProfileContext);
  const [checked, setChecked] = useState(checkedAccordions.includes(title));

  useEffect(() => {
    // Update the component's checked state based on changes in the context
    setChecked(checkedAccordions.includes(title));
  }, [checkedAccordions, title]);

  const handleCheck = (event) => {
    event.stopPropagation(); // Prevents the accordion from toggling
    setChecked(!checked);
    updateCheckedAccordions(title, !checked); // Update the context and Firestore
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={onChange}
        sx={{ background: "#363240" }}
      >
        <AccordionSummary
          aria-controls="panel-content"
          id="panel-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginTop: 5,
            // borderRight: expanded
            //   ? "2px solid #6b6b6b"
            //   : "2px solid transparent",
            // borderLeft: expanded
            //   ? "2px solid #6b6b6b"
            //   : "2px solid transparent",
            // borderTop: expanded ? "2px solid #6b6b6b" : "2px solid transparent",
            // borderBottom: expanded ? "none" : "2px solid transparent",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
            borderBottomRightRadius: expanded ? 0 : "4px",
            borderBottomLeftRadius: expanded ? 0 : "4px",
          }}
        >
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onChange();
            }}
            size="small"
            style={{
              color: "#fff",
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
          <Box
            sx={{ display: "flex", alignItems: "center", ml: { xs: 0, md: 2 } }}
          >
            {React.cloneElement(icon, {
              sx: {
                width: 20,
                mr: 1,
                display: { xs: "none", md: "block" },
                color: "#fff",
              },
            })}
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 18, md: 24 },
                color: "#fff",
              }}
            >
              {title}
            </Typography>
          </Box>

          <Box flex="1" />
          <IconButton
            onClick={handleCheck}
            style={{ marginRight: "8px" }}
            disabled={
              title.startsWith("Phone Call with") ||
              title.startsWith("Zoom Meeting with")
            }
          >
            <AnimatedCheckmark checked={checked} onClick={handleCheck} />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails
          style={{
            // borderLeft: "2px solid #6b6b6b",
            // borderRight: "2px solid #6b6b6b",
            // borderBottom: "2px solid #6b6b6b",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            transition: "all 0.3s ease-in-out",
            paddingTop: 20,
            paddingBottom: 40,
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
