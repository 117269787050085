// VideoPlayer.tsx
import React from "react";
import useEmbedCode from "./useEmbedCode";
import Box from "@mui/material/Box";

const CUSTOMER_ID = "JmJ8Wxcs";

export const VideoPlayer = ({ embedId }) => {
  useEmbedCode(CUSTOMER_ID, embedId);

  return (
    <div
      style={{
        marginTop: -20,
        marginBottom: 50,
        margin: "auto",
      }}
    >
      <div
        id={`vidalytics_embed_${embedId}`}
        style={{
          position: "relative",
          width: "100%",
        }}
      />
    </div>
  );
};

export default VideoPlayer;
