import React, { useContext } from "react";
import CustomAccordion from "./CustomAccordion"; // Importing CustomAccordion
import { ProfileContext } from "../context/ProfileContext";

export default function AccordionGroup({ children }) {
  const [expandedIndex, setExpandedIndex] = React.useState(null);
  const isFirstRender = React.useRef(true);
  const context = useContext(ProfileContext);

  const handleToggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  React.useEffect(() => {
    if (isFirstRender.current) {
      const checkedAccordions = context.checkedAccordions || [];
      const firstUncheckedIndex = React.Children.toArray(children).findIndex(
        (child) => !checkedAccordions.includes(child.props.title)
      );
      setExpandedIndex(firstUncheckedIndex === -1 ? null : firstUncheckedIndex);
      isFirstRender.current = false;
    }
  }, [children, context.checkedAccordions]);

  return (
    <div>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          expanded: index === expandedIndex,
          onChange: () => handleToggleExpand(index),
        })
      )}
    </div>
  );
}
