import CustomAccordion from "./CustomAccordion";
import VideoPlayer from "./VideoPlayer";
import DueDiligence from "./DueDiligence";
import AccordionGroup from "./AccordionGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import SchoolIcon from "@mui/icons-material/School";
import FlagIcon from "@mui/icons-material/Flag";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import GroupIcon from "@mui/icons-material/Group";
import ReviewsIcon from "@mui/icons-material/Reviews";
import VideoChatIcon from "@mui/icons-material/VideoChat";
import { ProfileContext } from "../context/ProfileContext";
import { Link } from "@mui/material";
import moment from "moment-timezone";
import SpouseDiscussion from "./SpouseDiscussion";

export default function HomepageAccordions({}) {
  const context = useContext(ProfileContext);

  function formatPhoneNumber(phoneNumber) {
    if (/^\d{10}$/.test(phoneNumber)) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return phoneNumber;
  }

  return (
    <Box sx={{ m: 1, mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "space-between" },
          mb: { xs: 0, md: -2 },
        }}
      >
        <Typography
          variant="h6"
          textAlign={{ xs: "center", md: "left" }}
          sx={{ mb: 1 }}
        >
          What are my next steps?
        </Typography>
        <Box
          component="img"
          src="/click-done.svg"
          alt="Logo"
          sx={{
            width: 150,
            zIndex: 2,
            display: { xs: "none", md: "block" },
          }}
        />
      </Box>
      <AccordionGroup>
        <CustomAccordion
          title={`Phone Call with ${
            context.setter ? context.setter.firstname : "Advisor"
          }`}
          defaultChecked={true}
          icon={<PhoneForwardedIcon />}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Avatar
                src={
                  context.setter
                    ? context.setter.headshot
                    : "/default-avatar.png"
                }
                sx={{ width: 100, height: 100 }}
              />
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", my: 1, color: "#fff" }}
              >
                {context.setter
                  ? `${context.setter?.firstname} ${context.setter?.lastname}`
                  : " Advisor"}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontStyle: "italic",
                  fontWeight: "light",
                  fontSize: "0.875rem",
                  textAlign: "center",
                }}
              >
                {context.setter?.description
                  ? context.setter?.description
                  : `Our Advisors have extensive experience in real estate investing and client advisory. They are dedicated to helping clients achieve their personal and financial goals.`}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                mx: { xs: 0, md: 4 }, // Adjust margin for spacing between the two columns
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography sx={{ fontSize: 16, my: 2 }} variant="h6">
                When:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  {moment(
                    context.hubspotData.mostRecentTriageMeeting?.startTime
                  )
                    .tz(
                      Intl.DateTimeFormat().resolvedOptions().timeZone ||
                        "America/Los_Angeles"
                    )
                    .format("MMMM D [at] h:mm A z")}
                </span>
              </Typography>

              <Typography sx={{ fontSize: 16, my: 2 }} variant="h6">
                Duration:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  {(context.hubspotData.mostRecentTriageMeeting?.endTime -
                    context.hubspotData.mostRecentTriageMeeting?.startTime) /
                    60000}{" "}
                  Minutes
                </span>
              </Typography>
              <Typography sx={{ fontSize: 16, my: 2 }} variant="h6">
                Where:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  {context.hubspotData.phone
                    ? `We will call you at ${formatPhoneNumber(
                        context.hubspotData.phone
                      )}`
                    : "We will call your phone number!"}
                </span>
              </Typography>
            </Box>
          </Box>
        </CustomAccordion>
        {/* <CustomAccordion
          title="Tell us About your Goals"
          icon={<FlagIcon />}
        ></CustomAccordion>
        <CustomAccordion
          title="Financial Exercise"
          icon={<CandlestickChartIcon />}
        ></CustomAccordion>
        <CustomAccordion
          title="Explain different product packages"
          icon={<GroupIcon />}
        ></CustomAccordion>
         */}
        <CustomAccordion title="Get to Know Us" icon={<ReviewsIcon />}>
          <DueDiligence />
        </CustomAccordion>
        <CustomAccordion
          title="Involve your Spouse/Partner"
          icon={<GroupIcon />}
        >
          <SpouseDiscussion />
        </CustomAccordion>
        <CustomAccordion
          title={`Zoom Meeting with ${
            context.closer ? context.closer.firstname : "Senior Advisor"
          }`}
          icon={
            <img
              src="/zoom.svg"
              alt="Zoom Logo"
              style={{
                width: 20,
                marginRight: "8px",
              }}
            />
          }
        >
          {context.hubspotData.mostRecentSalesMeeting ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Avatar
                  src={
                    context.closer
                      ? context.closer.headshot
                      : "/default-avatar.png"
                  }
                  sx={{ width: 100, height: 100 }}
                />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", my: 1, color: "#fff" }}
                >
                  {context.closer
                    ? `${context.closer?.firstname} ${context.closer?.lastname}`
                    : "Senior Advisor"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: "italic",
                    fontWeight: "light",
                    fontSize: "0.875rem",
                    textAlign: "center",
                  }}
                >
                  {context.closer?.description
                    ? context.closer?.description
                    : `Our Advisors have extensive experience in real estate investing and client advisory. They are dedicated to helping clients achieve their personal and financial goals.`}
                </Typography>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  mx: 4, // Add margin for spacing between the two columns
                }}
              >
                <Typography sx={{ fontSize: 16, my: 2 }} variant="h6">
                  Where:
                  <Tooltip
                    title={
                      Date.now() <
                      context.hubspotData.mostRecentSalesMeeting?.startTime
                        ? `Meeting start time is not until ${new Date(
                            context.hubspotData.mostRecentSalesMeeting?.startTime
                          ).toLocaleString("en-US", {
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                            timeZone: "America/New_York",
                          })} EST`
                        : Date.now() >
                          context.hubspotData.mostRecentSalesMeeting?.endTime
                        ? `Meeting was scheduled for ${new Date(
                            context.hubspotData.mostRecentSalesMeeting?.startTime
                          ).toLocaleString("en-US", {
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                            timeZone: "America/New_York",
                          })} EST`
                        : "Meeting is now!"
                    }
                  >
                    <span style={{ cursor: "not-allowed" }}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#0B5CFF",
                          "&:hover": { backgroundColor: "#0649cf" },
                          "&.Mui-disabled": {
                            backgroundColor: "#0B5CFF",
                            color: "rgba(255, 255, 255, 0.7)",
                          },
                          "&.Mui-disabled img": {
                            opacity: 0.7,
                          },
                          ml: 1,
                        }}
                        href={
                          context.hubspotData.mostRecentSalesMeeting.zoomLink
                        }
                        disabled={
                          Date.now() <
                            context.hubspotData.mostRecentSalesMeeting
                              ?.startTime ||
                          Date.now() >
                            context.hubspotData.mostRecentSalesMeeting?.endTime
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "no wrap",
                          }}
                        >
                          <img
                            src="/zoom.svg"
                            alt="Zoom Logo"
                            style={{
                              width: 25,
                              mr: 4,
                              mb: -1,
                            }}
                          />
                          <Box sx={{ ml: 1, mt: 0.5 }}>Join Zoom</Box>
                        </Box>
                      </Button>
                    </span>
                  </Tooltip>
                </Typography>

                {/* <Typography sx={{ fontSize: 16, my: 2 }} variant="h6">
                Coffee on Us For the Meeting:{" "}
                <Tooltip title="You can redeem 1 hour before the meeting!">
                  <span style={{ cursor: "not-allowed" }}>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#06704b",
                        backgroundColor: "#f3fff0",
                        "&:hover": { backgroundColor: "#deedda" },
                        "&.Mui-disabled": {
                          backgroundColor: "#f3fff0",
                          color: "rgba(255, 255, 255, 0.7)",
                        },
                        "&.Mui-disabled img": {
                          opacity: 0.7,
                        },
                        ml: 1,
                      }}
                      href="https://starbucks.com/meetings.com"
                      disabled
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "no wrap",
                        }}
                      >
                        <img
                          src="/starbucks.svg"
                          alt="Starbucks Logo"
                          style={{
                            width: 25,
                            mr: 4,
                            mb: -1,
                          }}
                        />
                        <Box
                          sx={{
                            ml: 1,
                            mt: 0.5,
                            color: true ? "rgba(6, 112, 75, 0.7)" : "#06704b",
                          }}
                        >
                          Redeem Voucher
                        </Box>
                      </Box>
                    </Button>
                  </span>
                </Tooltip>
              </Typography> */}

                <Typography sx={{ fontSize: 16, my: 2 }} variant="h6">
                  When:{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#fff",
                      textTransform: "none",
                    }}
                  >
                    {moment(
                      context.hubspotData.mostRecentSalesMeeting?.startTime
                    )
                      .tz(
                        Intl.DateTimeFormat().resolvedOptions().timeZone ||
                          "America/Los_Angeles"
                      )
                      .format("MMMM D [at] h:mm A z")}
                  </span>
                </Typography>

                <Typography sx={{ fontSize: 16, my: 2 }} variant="h6">
                  Duration:{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#fff",
                      textTransform: "none",
                    }}
                  >
                    {(context.hubspotData.mostRecentSalesMeeting?.endTime -
                      context.hubspotData.mostRecentSalesMeeting?.startTime) /
                      60000}{" "}
                    Minutes
                  </span>
                </Typography>

                {Date.now() <
                context.hubspotData.mostRecentSalesMeeting?.startTime ? (
                  <Typography sx={{ fontSize: 16, my: 2 }} variant="h6">
                    <Link
                      href={
                        context.hubspotData.mostRecentSalesMeeting
                          .rescheduleLink
                      }
                      style={{
                        textDecoration: "underline",
                        color: "#fff",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      Click to Reschedule
                    </Link>
                  </Typography>
                ) : null}
                {/* <Typography sx={{ fontSize: 16, my: 2 }} variant="h6">
                Meeting Notes:{" "}
                <span style={{ fontWeight: "bold", color: "#fff", textTransform: "none" }}>
                  "Hey Evan, it was great meeting with you! Excited to have you
                  go through this page and show your wife. Let me know if I can
                  answer any questions for you. - Whitney"
                </span>
              </Typography> */}
              </Box>
            </Box>
          ) : (
            <Typography>More details coming soon!</Typography>
          )}
        </CustomAccordion>
      </AccordionGroup>
    </Box>
    // test
  );
}
