// useEmbedCode.tsx
import { useEffect } from "react";

const useEmbedCode = (customerId: string, embedId: string) => {
  const fullEmbedId = `vidalytics_embed_${embedId}`;

  useEffect(() => {
    (function (v, i, d, a, l, y, t, c, s) {
      y = "_" + d.toLowerCase();
      c = d + "L";
      if (!v[d]) {
        v[d] = {};
      }
      if (!v[c]) {
        v[c] = {};
      }
      if (!v[y]) {
        v[y] = {};
      }
      let vl = "Loader",
        vli = v[y][vl],
        vsl = v[c][vl + "Script"],
        vlf = v[c][vl + "Loaded"],
        ve = "Embed";
      if (!vsl) {
        vsl = function (u, cb) {
          if (t) {
            cb();
            return;
          }
          s = i.createElement("script");
          s.type = "text/javascript";
          s.async = 1;
          s.src = u;
          if (s.readyState) {
            s.onreadystatechange = function () {
              if (s.readyState === "loaded" || s.readyState === "complete") {
                s.onreadystatechange = null;
                vlf = 1;
                cb();
              }
            };
          } else {
            s.onload = function () {
              vlf = 1;
              cb();
            };
          }
          i.getElementsByTagName("head")[0].appendChild(s);
        };
      }
      vsl(l + "loader.min.js", function () {
        if (!vli) {
          const vlc = v[c][vl];
          vli = new vlc();
        }
        vli.loadScript(l + "player.min.js", function () {
          const vec = v[d][ve];
          t = new vec();
          t.run(a);
        });
      });
    })(
      window,
      document,
      "Vidalytics",
      fullEmbedId,
      `https://fast.vidalytics.com/embeds/${customerId}/${embedId}/`
    );

    // destroy player instance to prevent unexpected behaviour
    return () => {
      window._vidalytics?.embeds?.[fullEmbedId]?.player?.destroy();
      delete window._vidalytics?.embeds?.[fullEmbedId];
    };
  }, [customerId, embedId]);
};

export default useEmbedCode;
