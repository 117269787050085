import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(to bottom, #3E1391 0%, #000000 100%)", // Gradient from purple to black
    animation: "$backgroundAnimation 15s ease infinite",
  },
  "@keyframes backgroundAnimation": {
    "0%": { filter: "hue-rotate(0deg)" },
    "100%": { filter: "hue-rotate(360deg)" }, // Rotates the hue over 15 seconds
  },
}));

export const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <Box className={classes.backdrop}>
      <img
        src="/logo.svg"
        alt="FlipSystem Logo"
        style={{ maxWidth: "200px", marginTop: -20, marginBottom: 20 }}
      />
      <CircularProgress color="secondary" size={60} thickness={4} />
    </Box>
  );
};

export default LoadingScreen;
