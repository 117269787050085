import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import ConcernsIcon from "@mui/icons-material/ReportProblem";
import SolutionIcon from "@mui/icons-material/Lightbulb";
import NextStepsIcon from "@mui/icons-material/TaskAlt";
import CallIcon from "@mui/icons-material/CalendarToday";

function SpouseDiscussion() {
  return (
    <Box sx={{ px: { xs: 0, md: 4 } }}>
      <Typography paragraph>
        If you have a spouse, partner, friend, or family member that you’re
        thinking of involving into FlipSystem, use this section as a script to
        help guide your discussion to get them on board. Nothing to us is more
        important than alignment, so make sure that you get them on board.
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mt: 3, fontWeight: "bold", color: "#fff" }}
      >
        How to start the discussion
      </Typography>
      <Typography paragraph>
        The following is a great way to open up the conversation!
      </Typography>
      <Typography paragraph sx={{ fontStyle: "italic" }}>
        "I want to join FlipSystem and start investing in Real Estate. I have
        done a lot of research on this company and its founders and they are
        legit. I know that joining FlipSystem requires a significant time and
        financial commitment but it could significantly change our financial
        security and our family’s future. We need to talk to be sure that we are
        on the same page regarding this decision and be aligned on our goals if
        we are going to be successful. We should discuss any concerns and make a
        unified decision that is best for our family's future."
      </Typography>
      <Typography>
        Below are a list of talking points and questions that your spouse, or
        partner, may have about the program.
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mt: 3, fontWeight: "bold", color: "#fff" }}
      >
        What does FlipSystem do?
      </Typography>
      <Typography paragraph>
        Watch the video above with your partner or spouse! FlipSystem is a
        coaching program to help make money by investing in single family
        rentals. The program includes education, coaching, trusted resources,
        and a custom software portal to find and track all our deals.
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mt: 3, fontWeight: "bold", color: "#fff" }}
      >
        What are our common goals?
      </Typography>
      <Typography paragraph>
        Share your vision and goals, such as financial freedom, retirement,
        financial security, legacy of our children, etc. Discuss how these goals
        align with your family’s future and how FlipSystem can help you achieve
        those goals.
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mt: 3, fontWeight: "bold", color: "#fff" }}
      >
        How involved would you like to be?
      </Typography>
      <Typography paragraph>
        Discuss how your spouse/partner can support you in this journey. Our
        clients on average invest around 8 hours per week in FlipSystem. We want
        to make sure your family is onboard with this decision. Would your
        partner like to be involved in the day to day activities or would they
        like to let you run the business?
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mt: 3, fontWeight: "bold", color: "#fff" }}
      >
        How can we financially invest in FlipSystem and in real estate?
      </Typography>
      <Typography paragraph>
        Review the financial information section with your spouse or partner to
        make sure you are on the same page and understand the financials
        together.
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mt: 3, fontWeight: "bold", color: "#fff" }}
      >
        Any questions?
      </Typography>
      <Typography paragraph>
        List any concerns and questions your spouse/partner may have and bring
        them to the next meeting.
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mt: 3, fontWeight: "bold", color: "#fff" }}
      >
        What’s next?
      </Typography>
      <Typography paragraph>
        Have a great call, details are below and{" "}
        <span style={{ fontWeight: "bold", color: "#fff" }}>make sure</span> to
        bring your spouse or partner to the call.
      </Typography>
    </Box>
  );
}

export default SpouseDiscussion;
