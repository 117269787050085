import React, { useContext } from "react";
import { ProfileContext } from "../context/ProfileContext";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  IconButton,
  Link,
} from "@mui/material";
import { addLinkToClickedLinks } from "./../util/db"; // Import the function to add clicked links

import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import FlagIcon from "@mui/icons-material/Flag";
import SchoolIcon from "@mui/icons-material/School";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import GroupIcon from "@mui/icons-material/Group";
import ReviewsIcon from "@mui/icons-material/Reviews";
import VideoChatIcon from "@mui/icons-material/VideoChat";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";

const sections = [
  {
    title: "Check FlipSystem Ratings",
    links: [
      {
        name: "Better Business Bureau (BBB)",
        url: "https://www.bbb.org/us/fl/miami/profile/real-estate-investing/flipsystem-llc-0633-92035767",
        image:
          "https://images.clickfunnels.com/c1/5dac9c3d7349b4a901b3eb235322dc/better-business-bureau.png",
        rating: "5.0 / 5.0",
      },
      {
        name: "TrustPilot",
        url: "https://www.trustpilot.com/review/flipsystem.com",
        image:
          "https://images.clickfunnels.com/bb/0d3bbebe504d6f89aa87bb70d47bb0/trustpilot3.png",
        rating: "4.8 / 5.0",
      },
      {
        name: "Google",
        url: "https://g.co/kgs/PaeS3zh",
        image:
          "https://images.clickfunnels.com/29/2a9d6630c64399b9b8089d47f3db60/google.png",
        rating: "4.9 / 5.0",
      },
    ],
    color: "linear-gradient(180deg, #fff, #ededed)",
    textColor: "#000",
  },
  {
    title: "Review Our Websites",
    links: [
      {
        name: "MartelTurnkey.com",
        url: "https://martelturnkey.com",
        image: "gtku/mtklogo.png",
      },
      {
        name: "FlipSystem.com",
        url: "https://flipsystem.com",
        image: "gtku/fslogo.png",
      },
    ],
    color: "linear-gradient(180deg, #fff, #ededed)",
  },
  {
    title: "Case Studies",
    links: [
      {
        name: "How This Police Officer Flipped 2 Houses in 6 Months",
        url: "https://www.youtube.com/watch?v=IxxJkRPHvgI",
        image: "gtku/police.jpg",
      },
      {
        name: "Case Study: He Bought 5 Houses in 30 days",
        url: "https://www.youtube.com/watch?v=VB2KVJeYR7I",
        image: "gtku/vito.jfif",
      },
      {
        name: "Case Study: How He Made $45,000 in 6 Months Flipping Houses",
        url: "https://www.youtube.com/watch?v=iBHRGSj3uno",
        image: "gtku/evan.jfif",
      },
      {
        name: "Case Study: How He Flipped 5 Homes in 12 Months",
        url: "https://www.youtube.com/watch?v=x7YriqE9no4",
        image: "gtku/connor.jfif",
      },
    ],
    color: "linear-gradient(180deg, #fff, #ededed)",
  },
  {
    title: "Testimonials",
    links: [
      {
        name: "They Bought Two Deals in 6 Months",
        url: "https://www.youtube.com/watch?v=F9NdJ50TD1s",
        image: "gtku/2in6.jpg",
      },
      {
        name: "He Bought One House in 7 Months",
        url: "https://www.youtube.com/watch?v=au-NtCsY9PQ",
        image: "gtku/chris.jpg",
      },
      {
        name: "She Bought One Deal in 2 Months",
        url: "https://www.youtube.com/watch?v=PP0IkZDr_C0",
        image: "gtku/yodi.jpg",
      },
      {
        name: "He Bought One Deal In 12 Months",
        url: "https://www.youtube.com/watch?v=CJkHKIdBSOY",
        image: "gtku/angel.jpg",
      },
      {
        name: "She Bought One House in 2 Months",
        url: "https://www.youtube.com/watch?v=Ps9K9peOcFk",
        image: "gtku/tran.jpg",
      },
      {
        name: "She Flipped One House in 7 Months",
        url: "https://www.youtube.com/watch?v=r09i84X1J2M",
        image: "gtku/tiffany.jpg",
      },

      {
        name: "Recent Testimonial Interview with Blanca",
        url: "https://www.youtube.com/watch?v=pXi0-ajVjzI",
        image: "gtku/blanca.jpg",
      },
    ],
    color: "linear-gradient(180deg, #fff, #ededed)",
  },

  {
    title: "Social Media Content",
    links: [
      {
        name: "Beginners Guide to Investing in Real Estate in 2025 (1.5+ Hours)",
        url: "https://www.youtube.com/watch?v=RjXKoK8yoFM",
        image: "gtku/antoinec6.jpg",
      },
      {
        name: "Antoine and Eric on Digital Social Hour Podcast (1M+ Subscribers)",
        url: "https://www.youtube.com/watch?v=BpistrjdBl4",
        image: "gtku/digitalsocialhour.jpeg",
      },
      {
        name: "FlipSystem Overview Interview with Co-Founder Eric Martel",
        url: "https://www.youtube.com/watch?v=acQyt2_itcY",
        image: "gtku/eric-overview.png",
      },
      {
        name: "Antoine Martel on BiggerPockets Podcast",
        url: "https://www.youtube.com/watch?v=6TvPRpEHGTg",
        image: "gtku/bplogo.png",
      },
    ],
    color: "linear-gradient(180deg, #fff, #ededed)",
  },
  {
    title: "Articles Written About the Founders",
    links: [
      {
        name: "Eric - Shout Out Los Angeles",
        url: "https://shoutoutla.com/meet-eric-martel-entrepreneur-real-estate-investor-author-and-philanthropist/",
        image: "gtku/shoutoutlalogo.png",
      },
      {
        name: "BusinessInsider",
        url: "https://www.businessinsider.com/real-estate-investing-how-young-college-owns-89-rental-units-2021-11",
        image: "gtku/businessinsider4.png",
      },
      {
        name: "Is the American Dream Attainable?",
        url: "https://www.entrepreneur.com/leadership/is-the-american-dream-attainable/361237",
        image: "gtku/entrepreneur.png",
      },
    ],
    color: "linear-gradient(180deg, #fff, #ededed)",
    textColor: "#000",
  },
];

function DueDiligence() {
  const { hubspotData } = useContext(ProfileContext);

  const handleLinkClick = async (url) => {
    // Add the link to Firestore before redirecting
    await addLinkToClickedLinks(hubspotData.hs_object_id, url);

    // Navigate to the desired page
    window.location.href = url;
  };

  return (
    <Box sx={{ px: { xs: 0, md: 4 } }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#fff",
          fontWeight: "bold",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        Meet the Founders
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            elevation={1}
            sx={{
              p: 3,
              display: "flex",
              alignItems: "center",
              height: "100%",
              background: "#363240",
              flexDirection: { xs: "column", sm: "row" },
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Avatar
              src={
                "https://ca.slack-edge.com/T04HXDV7TR8-U04HGTBLN4F-e08dd3057f37-512"
              }
              sx={{
                width: 150,
                height: 150,
                mb: { xs: 2, sm: 0 },
                mr: { sm: 3 },
              }}
            />
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#fff" }}
              >
                Antoine Martel
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "start" },
                }}
              >
                <IconButton
                  onClick={() =>
                    handleLinkClick(
                      "https://www.instagram.com/martelantoine/?hl=en"
                    )
                  }
                >
                  <img
                    src="instagram.svg"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() =>
                    handleLinkClick("https://www.tiktok.com/@martelantoine")
                  }
                >
                  <img
                    src="tiktok.svg"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() =>
                    handleLinkClick(
                      "https://www.linkedin.com/in/antoinemmartel/"
                    )
                  }
                >
                  <img
                    src="linkedin.svg"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() =>
                    handleLinkClick("https://www.youtube.com/@martelantoine")
                  }
                >
                  <img
                    src="youtube.svg"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() =>
                    handleLinkClick(
                      "https://www.amazon.com/Millennials-Investing-Flowing-Rental-Properties-ebook/dp/B07XKWTJ72"
                    )
                  }
                >
                  <img
                    src="amazon.svg"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </IconButton>
              </Box>

              <Typography
                variant="body2"
                sx={{
                  fontStyle: "italic",
                  fontWeight: "light",
                  fontSize: "0.875rem",
                }}
              >
                Antoine Martel is a real estate entrepreneur from San Mateo,
                California. His proven real estate investment strategies have
                enabled countless clients to realize passive income and
                financial freedom through out-of-state turnkey rental
                properties.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            elevation={3}
            sx={{
              p: 3,
              display: "flex",
              alignItems: "center",
              height: "100%",
              background: "#363240",
              flexDirection: { xs: "column", sm: "row" },
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Avatar
              src={
                "https://ca.slack-edge.com/T04HXDV7TR8-U058ZG5QTQU-d39942833aeb-512"
              }
              sx={{
                width: 125,
                height: 125,
                mb: { xs: 2, sm: 0 },
                mr: { sm: 3 },
              }}
            />
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#fff" }}
              >
                Eric Martel
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "start" },
                }}
              >
                <IconButton
                  onClick={() =>
                    handleLinkClick(
                      "https://www.instagram.com/ericmartelofficial/?hl=en"
                    )
                  }
                >
                  <img
                    src="instagram.svg"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() =>
                    handleLinkClick(
                      "https://www.tiktok.com/@ericmartelofficial?lang=en"
                    )
                  }
                >
                  <img
                    src="tiktok.svg"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() =>
                    handleLinkClick(
                      "https://www.linkedin.com/in/ericmartelofficial/"
                    )
                  }
                >
                  <img
                    src="linkedin.svg"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() =>
                    handleLinkClick(
                      "https://www.youtube.com/@ericmartelofficial"
                    )
                  }
                >
                  <img
                    src="youtube.svg"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() =>
                    handleLinkClick(
                      "https://www.amazon.com/Stop-Trading-Your-Time-Money-ebook/dp/B08KWM5PW1"
                    )
                  }
                >
                  <img
                    src="amazon.svg"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </IconButton>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  fontStyle: "italic",
                  fontWeight: "light",
                  fontSize: "0.875rem",
                }}
              >
                Eric Martel bought his first apartment at 18 and worked as an
                actuary before shifting to tech. After losing in the 2001
                Dot-com crash, he sought passive income, eventually founding
                MartelTurnkey in real estate. His rapid success enabled early
                retirement. Now, he aims to help others avoid similar pitfalls.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {sections.map((section, index) => (
        <Box key={index}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "#fff",
              fontWeight: "bold",
              mt: 3,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {section.title}
          </Typography>
          <Grid container spacing={2}>
            {section.links.map((link, idx) => (
              <Grid item xs={12} sm={6} md={4} key={idx}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%", // Ensures full height
                    background: section.color,
                  }}
                >
                  <CardActionArea
                    component="button" // Changed to button to handle click with function
                    onClick={() => handleLinkClick(link.url)} // Call the handleLinkClick function
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 1,
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                        py: 2,
                        px: 0,
                      }}
                    >
                      {link.image && (
                        <img
                          src={link.image}
                          style={{ width: "200px", marginBottom: "8px" }}
                        />
                      )}
                      {link.rating &&
                        section.title !== "Review Our Websites" && (
                          <Typography
                            component="div"
                            sx={{
                              color: "#000",
                              textAlign: "center",
                              fontSize: 15,
                              width: "100%",
                            }}
                          >
                            {link.rating}
                          </Typography>
                        )}

                      {section.title !== "Review Our Websites" && (
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{
                            color: "#000",
                            textAlign: "center",
                            fontSize: 20,
                            mt: 2,
                          }}
                        >
                          {link.name}
                        </Typography>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
}

export default DueDiligence;
