import React, { useContext, useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import AdvisorsComponent from "../components/AdvisorsComponent";
import ScholarshipForm from "../components/ScholarshipForm";
import AdminView from "../components/AdminView";
import HomepageAccordions from "../components/HomepageAccordions";
import Footer from "../components/Footer";
import { ProfileContext } from "../context/ProfileContext"; // Make sure this path matches the actual import path in your project structure
import VideoPlayer from "../components/VideoPlayer";

function IndexPage() {
  const { isAdminViewVisible, setAdminViewVisible, dataLoaded, allData } =
    useContext(ProfileContext);
  const [contactData, setContactData] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const hs_email = queryParams.get("hs_email");

    if (hs_email) {
      fetch(`/api/getContact?hs_email=${hs_email}`)
        .then((response) => response.json())
        .then((data) => setContactData(data))
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, []);

  const videoEmbed = allData?.hubspot?.video_to_watch || "WJpmC_ecI3Hzx6S5";

  console.log("EMBED ", videoEmbed);

  return (
    <>
      {dataLoaded && (
        <>
          <Header />
          <Container>
            <AdminView />
            <VideoPlayer embedId={videoEmbed} userId="JmJ8Wxcs" />
            <ScholarshipForm />
            <HomepageAccordions />
            <AdvisorsComponent />
          </Container>
          <Footer />
        </>
      )}
    </>
  );
}

export default IndexPage;
