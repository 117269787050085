import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <AppBar
      position="static"
      color="transparent"
      component="footer"
      elevation={0}
      sx={{ my: 2 }}
    >
      <Container maxWidth="md">
        <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
          <img
            src="/logo.svg"
            alt="FlipSystem Logo"
            style={{ maxWidth: "150px" }}
          />
          <Typography variant="body1" sx={{ marginY: 2, fontSize: 14 }}>
            © {currentYear} FlipSystem, LLC. All rights reserved.
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;
