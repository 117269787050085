import React, { createContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { fetchAllData, saveProfileData } from "./../util/db";
import { useRouter } from "./../util/router";
import LoadingScreen from "../components/LoadingScreen";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const router = useRouter();
  const hs_id = router.query.hs_id;

  const [isAdminViewVisible, setAdminViewVisible] = useState(false);
  const [grantAmount, setGrantAmount] = useState(null);
  const [circumstancesQuestion, setCircumstancesQuestion] = useState(null);
  const [additionalQuestionAnswer, setAdditionalQuestionAnswer] =
    useState(null);
  const [additionalQuestion, setAdditionalQuestion] = useState(null);
  const [grantSubmitted, setGrantSubmitted] = useState(false);
  const [grantApproved, setGrantApproved] = useState(false);
  const [showGrant, setShowGrant] = useState(false);
  const [checkedAccordions, setCheckedAccordions] = useState([]);

  const [hubspotData, setHubspotData] = useState({});
  const [profile, setProfile] = useState(null);
  const [setter, setSetter] = useState(null);
  const [closer, setCloser] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { data, status, error } = useQuery(
    ["allData", hs_id],
    () => fetchAllData(hs_id),
    { enabled: !!hs_id }
  );

  useEffect(() => {
    if (data) {
      const { hubspot, profiles, advisors } = data;
      const newCheckedAccordions = [...(profiles?.checkedAccordions || [])];
      const setter = advisors.find(
        (advisor) => advisor.email === hubspot.setterEmail
      );

      if (
        hubspot.mostRecentTriageMeeting &&
        new Date(hubspot.mostRecentTriageMeeting?.startTime) <= new Date()
      ) {
        const accordionTitle =
          "Phone Call with " + (setter ? setter.firstname : "Setter");

        if (hubspot.mostRecentTriageMeeting.outcome !== "NO_SHOW") {
          if (!newCheckedAccordions.includes(accordionTitle)) {
            newCheckedAccordions.push(accordionTitle);
          }
        } else {
          const index = newCheckedAccordions.indexOf(accordionTitle);
          if (index > -1) {
            newCheckedAccordions.splice(index, 1);
          }
        }
      }

      if (
        hubspot.mostRecentSalesMeeting &&
        new Date(hubspot.mostRecentSalesMeeting?.startTime) <= new Date()
      ) {
        const closer = advisors.find(
          (advisor) => advisor.email === hubspot.ownerEmail
        );
        const accordionTitle =
          "Zoom Meeting with " + (closer ? closer.firstname : "Closer");

        const index = newCheckedAccordions.indexOf(accordionTitle);
        if (hubspot.mostRecentSalesMeeting.outcome !== "NO_SHOW") {
          if (index === -1) {
            newCheckedAccordions.push(accordionTitle);
          }
        } else {
          if (index > -1) {
            newCheckedAccordions.splice(index, 1);
          }
        }
      }

      setCheckedAccordions(newCheckedAccordions);
      saveProfileData(hs_id, { checkedAccordions: newCheckedAccordions });
    }
  }, [data]);

  // console.log("CHECKED ACCORDIONS ", checkedAccordions);

  useEffect(() => {
    if (data) {
      console.log("All data fetched:", data);
      setHubspotData(data.hubspot);
      setProfile(data.profiles);
      // New ENV

      if (data.profiles) {
        setGrantAmount(data.profiles.grantAmount || 3000);
        setCircumstancesQuestion(data.profiles.circumstancesQuestion || null);
        setAdditionalQuestion(data.profiles.additionalQuestion || null);
        setAdditionalQuestionAnswer(
          data.profiles.additionalQuestionAnswer || null
        );
        setGrantSubmitted(data.profiles.grantSubmitted || false);
        setGrantApproved(data.profiles.grantApproved || false);
        setShowGrant(data.profiles.showGrant || false);
      }

      if (data.hubspot.setterEmail) {
        const setterAdvisor = data.advisors.find(
          (advisor) => advisor.email === data.hubspot.setterEmail
        );
        setSetter(
          setterAdvisor
            ? {
                email: setterAdvisor.email,
                firstname: setterAdvisor.firstname,
                lastname: setterAdvisor.lastname,
                phone: setterAdvisor.phone,
                headshot: setterAdvisor.headshot,
                description: setterAdvisor.description,
              }
            : null
        );
      } else {
        setSetter(null);
      }

      if (data.hubspot.ownerEmail) {
        const closerAdvisor = data.advisors.find(
          (advisor) => advisor.email === data.hubspot.ownerEmail
        );
        setCloser(
          closerAdvisor
            ? {
                email: closerAdvisor.email,
                firstname: closerAdvisor.firstname,
                lastname: closerAdvisor.lastname,
                phone: closerAdvisor.phone,
                headshot: closerAdvisor.headshot,
                description: closerAdvisor.description,
              }
            : null
        );
      } else {
        setCloser(null);
      }

      setDataLoaded(true);
    }
  }, [data]);

  const handleGrantApproval = (approved) => {
    setGrantApproved(approved);
    saveProfileData(hs_id, { grantApproved: approved });
  };

  // Function to update checked accordions
  const updateCheckedAccordions = (title, isChecked) => {
    let updatedList;
    if (isChecked) {
      // Add the title to the list if it is checked and not already in the list
      if (!checkedAccordions.includes(title)) {
        updatedList = [...checkedAccordions, title];
      }
    } else {
      // Remove the title from the list if it is unchecked
      updatedList = checkedAccordions.filter((item) => item !== title);
    }

    // Update the state and save to Firestore only if there are changes
    if (updatedList && updatedList.length !== checkedAccordions.length) {
      setCheckedAccordions(updatedList);
      saveProfileData(hs_id, { checkedAccordions: updatedList });
    }
  };

  const handleScholarshipSubmit = (event, scholarshipData) => {
    event.preventDefault();

    const { scholarshipAmount, scholarshipReason, additionalQuestionAnswer } =
      scholarshipData;

    setGrantAmount(scholarshipAmount);
    setCircumstancesQuestion(scholarshipReason);
    setAdditionalQuestion(getAdditionalQuestion(scholarshipAmount));
    setAdditionalQuestionAnswer(additionalQuestionAnswer);
    setGrantSubmitted(true);

    saveProfileData(hs_id, {
      grantAmount: scholarshipAmount,
      circumstancesQuestion: scholarshipReason,
      additionalQuestion: getAdditionalQuestion(scholarshipAmount),
      additionalQuestionAnswer: additionalQuestionAnswer,
      grantSubmitted: true,
    });
  };

  const toggleGrantVisibility = () => {
    const newShowGrantStatus = !showGrant;
    setShowGrant(newShowGrantStatus);
    saveProfileData(hs_id, { showGrant: newShowGrantStatus }); // Save the new showGrant status to Firestore
  };

  const getAdditionalQuestion = (amount) => {
    switch (amount) {
      case "3000":
        return "Describe a recent project or endeavor where you took the first step towards a significant achievement. How do you plan to apply this initiative-taking attitude to real estate investment?";
      case "5000":
        return "Discuss a situation where you had to overcome obstacles to build something important, be it a project, relationship, or career opportunity. How will these experiences help you manage the complexities of real estate?";
      case "11000":
        return "Provide an example of how you have led a team or project to success. What leadership qualities do you think are essential for successfully running a real estate business, and how do you plan to apply them?";
      default:
        return "";
    }
  };

  if (status === "loading") return <LoadingScreen />;
  if (error) return <span>Error fetching data: {error.message}</span>;

  return (
    <ProfileContext.Provider
      value={{
        isAdminViewVisible,
        setAdminViewVisible,
        grantAmount,
        setGrantAmount,
        circumstancesQuestion,
        setCircumstancesQuestion,
        additionalQuestion,
        setAdditionalQuestion,
        additionalQuestionAnswer,
        setAdditionalQuestionAnswer,
        grantSubmitted,
        setGrantSubmitted,
        grantApproved,
        setGrantApproved,
        showGrant,
        setShowGrant,
        handleScholarshipSubmit,
        allData: data,
        dataStatus: status,
        hubspotData,
        profile,
        setter,
        closer,
        dataLoaded,
        checkedAccordions,
        updateCheckedAccordions,
        handleGrantApproval,
        toggleGrantVisibility,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
